<script>
  /**
   * DEPRECATED. Do not use and delete when there are no references to this component left. A button that can be toggled on or off. If it is within a `BaseButtonToggleGroup`, it behaves like a radio button, its `checked` state is based on whether its `value` is the same as the group’s `value`, and `v-model` is only supported on the group. If it is standalone (not inside a `BaseButtonToggleGroup`), then it behaves like a checkbox, its `checked` state is based on the `checked` prop, and `v-model` is supported.
   */
  export default {
    inject: {
      group: {
        from: 'BaseButtonToggleGroup',
        default: undefined,
      },
    },
    model: {
      prop: 'checked',
      event: 'change',
    },
    props: {
      /** Only if inside a `BaseButtonToggleGroup`. The value to use when the button is checked. */
      value: {
        type: String,
        default: undefined,
      },
      /** Only if *not* inside a `BaseButtonToggleGroup`. Whether the button is checked. */
      checked: {
        type: Boolean,
        default: false,
      },
      /** Whether the button is disabled. A disabled button is grayed out and cannot be focused or toggled. */
      disabled: {
        type: Boolean,
        default: false,
      },
    },
    computed: {
      inputType() {
        if (this.group !== undefined) {
          return 'radio'
        }
        return 'checkbox'
      },
      inputName() {
        if (this.inputType === 'radio') {
          return this.group.inputName
        }
        return undefined
      },
      inputValue() {
        if (this.inputType === 'radio') {
          return this.value
        }
        return undefined
      },
      inputChecked() {
        switch (this.inputType) {
          case 'radio':
            return Boolean(this.value && this.value === this.group.value)
          case 'checkbox':
            return this.checked
          default:
            return undefined
        }
      },
      listeners() {
        const { change, ...listeners } = this.$listeners
        return listeners
      },
    },
    methods: {
      change($event) {
        if (this.inputType === 'radio') {
          this.group.$emit('change', this.value)
          return
        }
        /** Emitted when the button behaves like a checkbox (not inside a `BaseButtonToggleGroup`) and is toggled on or off. */
        this.$emit('change', $event.target.checked)
      },
    },
  }
</script>

<template>
  <label class="flex flex-col">
    <input
      :type="inputType"
      :name="inputName"
      :value="inputValue"
      :checked="inputChecked"
      :disabled="disabled"
      class="input absolute left-0 top-0 opacity-0"
      v-on="listeners"
      @change="change"
    />
    <span
      class="button min-h-15 flex-grow py-2px rounded-sm border font-semibold text-lg leading-tight transition duration-200"
    >
      <span class="block p-4 overflow-hidden ellipsis">
        <!-- @slot The button’s label. Should contain text. -->
        <slot />
      </span>
    </span>
  </label>
</template>

<style lang="postcss" scoped>
  .button {
    @apply border-gray-active bg-white;

    .input:not(:disabled) ~ & {
      @apply cursor-pointer;
    }

    .input:not(:disabled):focus ~ & {
      @apply border-primary border-opacity-50 shadow-outline-primary-50;
    }

    .input:not(:disabled):hover ~ & {
      @apply border-primary-bright;
    }

    .input:not(:disabled):active ~ & {
      @apply border-transparent bg-primary-dark text-white transition-none;
    }

    .input:not(:disabled):checked ~ & {
      @apply border-transparent bg-primary text-white;
    }

    .input:not(:disabled):checked:hover ~ & {
      @apply bg-primary-bright;
    }

    .input:not(:disabled):checked:active ~ & {
      @apply bg-primary-dark;
    }

    .input:disabled ~ & {
      @apply border-transparent bg-gray-lighter text-gray-dark;
    }

    .input:disabled:checked ~ & {
      @apply border-transparent bg-gray-light text-white;
    }
  }
</style>
